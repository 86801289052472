
import './App.css';
import Hero from "./components/UI/Hero";
import Counter from "./components/UI/Counter";
import About from "./components/UI/About";
import Projects from "./components/UI/Projects";
import Footer from "./components/Footer/Footer";

function App() {
  return <>
    <Hero/>
    <Counter/>
    <About/>
    <Projects/>
    <Footer/>
  </>
}

export default App;
