import React from "react";
import "../../styles/about.css";

const About = () => {
    return <section id="about">
        <div className="container">
            <div className="about__wrapper">
                <div className="about__content">
                    <h2 className="highlight">O FIRMIE</h2>
                    <div className="description about__content-desc">
                    <blockquote class="highlight">
                <h3>
                  &emsp;Zajmujemy się opracowywaniem opinii, ekspertyz i
                  dokumentacji budowlanych, w branży konstrukcyjnej obiektów
                  budownictwa lądowego i wodnego. Wieloletnie doświadczenie w
                  projektach budowli kubaturowych i technologicznych oczyszczalni
                  ścieków, zbiorników magazynowych paliw i substancji ciekłych,
                  a także nietypowych konstrukcji stalowych takich jak np.:
                  maszty kratowe z odciągami, pozwala dostarczać klientom
                  rozwiązania sprawdzone i proste w realizacji.
                </h3>
              </blockquote>
              <blockquote class="highlight">
                <h3>
                  &emsp;Dziesiątki wykonanych ekspertyz w zakresie budownictwa
                  powszechnego i przemysłowego, ze szczególną uwagą poświęconą
                  zagadnieniom fizyki budowli oraz korozji betonu i stali, pozwala
                  na niezwłoczne rozwiązania typowych problemów występujących w
                  użytkowaniu budynków i budowli.
                </h3>
              </blockquote>
              <blockquote class="highlight">
                <h3>
                  &emsp;Posługujemy się w swojej pracy nowoczesnymi programami
                  komputerowymi jak również oprzyrządowaniem pomiarowym i
                  diagnostycznym, dostosowanym do skali problemu (kamera
                  termowizyjna, nowoczesny dron, szereg rejestratorów parametrów
                  środowiska, precyzyjne narzędzia pomiarowe)
                </h3>
              </blockquote>

                        <div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
 
};

export default About