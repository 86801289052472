import React from "react";
import "./footer.css";

const Footer = () => {

    const year = new Date().getFullYear()
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__logo">
                        <h2>KONTAKT</h2>
                        <p className="small__text-description">
                            Zapraszamy do kontaktowania się <br/>
                             telefonicznie albo drogą e-mail.
                        </p>
                    </div>
                    <div className="footer__quick-links">
                        <ul className="quick__links">
                            <i class="ri-phone-fill"></i> 
                            <h3>+48 691 430 250</h3>
                            <i class="ri-mail-fill"></i>
                            <h3>giw4@giw4.pl</h3>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="copyright">Copyright {year} All rights reserved. 07 październik 2023r.{" "}</p>
        </footer>
    );
}

export default Footer;
