import React from "react";
import '../../styles/projects.css';
import oczyszczalnia3 from '../../imgaes/image007.png';
import obudowa_wykopu from '../../imgaes/image005.png';
import algieria from '../../imgaes/image001.png';
import zadaszenie from '../../imgaes/image015.png';
import platforma from '../../imgaes/image009.png';
import stocznia from '../../imgaes/image010.png';
import stocznia3 from '../../imgaes/image012.png';
import hotel from '../../imgaes/image003.png';
import stocznia2 from '../../imgaes/image011.png';
import stocznia4 from '../../imgaes/image013.png';
import magazyn from '../../imgaes/image004.png';
import frontkolejowy from '../../imgaes/image002.png';
import wieza from '../../imgaes/image014.png';
import oczyszczalnia2 from '../../imgaes/oczyszczalnia_2.png';
import oczyszczalnia4 from '../../imgaes/image008.png';

const teamProjects = [
    {
        imgUrl: hotel,
        name: 'Zbrojenie płyty fundamentowej budynku apartamentowca w trudnych warunkach geotechnicznych. Kołobrzeg – okolice pasa technicznego',
        position: ''
    },
    {
        imgUrl: magazyn,
        name: 'Ekspertyza i projekt wzmocnienia magazynu śruty sojowej. Świnoujście.',
        position: ''
    },
    {
        imgUrl: obudowa_wykopu,
        name: 'Obudowa głębokiego wykopu w ciasnej zabudowie śródmiejskiej. Szczecin. Pale CFA średnicy 600mm',
        position: ''
    },
    {
        imgUrl: platforma,
        name: 'Zejście na plażę w Trzęsaczu. Konstrukcja stalowa, kratownice z rur okrągłych, pomost z rur prostokątnych. Elementy schodów podwieszanych połączne śrubami sprężanymi.',
        position: ''
    },
    {
        imgUrl: zadaszenie,
        name: 'Zadaszenie trybun na stadionie lekkoatletycznym w Świdwinie. Wykorzystano blachy trapezowe łukowe oraz słupy z rur prostokątnych wypełnionych betonem samozagęszczalnym.',
        position: ''
    },
    {
        imgUrl: frontkolejowy,
        name: 'Punkt załadunku cystern kolejowych surową ropą naftową.Konstrukcja kładki technicznej - rury prostokątne.',
        position: ''
    },
    {
        imgUrl: wieza,
        name: 'Wieża wsporcza urządzeń służących do pomiarów wiatru. H=120m. Rury i pręty okrągłe. Kilka poziomów odciągów lin z kotwami gruntowymi.',
        position: ''
    },
    {
        imgUrl: algieria,
        name: 'Kopuła zbiornika stalowego na wodę pitną - w trakcie budowy. D=46.30m; h=4.89m',
        position: ''
    },
    {
        imgUrl: stocznia,
        name: 'Estakad podsuwnicowa na terenie stoczni. Udźwig suwnicy Q=400t',
        position: ''
    },
    {
        imgUrl: stocznia3,
        name: 'Kotwa w betonie, zaczep lin hamujących kadłub statku, podczas wodowania.',
        position: ''
    },
    {
        imgUrl: stocznia2,
        name: 'Szczelna obudowa miejsca budowy w rzece – realizacja stopnia wybiegowego pochylni stoczniowej. ',
        position: ''
    },
    {
        imgUrl: stocznia4,
        name: 'Płyta pochylni stoczniowej wraz z torowiskiem. Beton samozagęszczalny. Grubosć płyty od 400mm do 1000mm.',
        position: ''
    },
    {
        imgUrl: oczyszczalnia3,
        name: 'Modernizacja i przebudowa komór napowietrzania w istniejącej oczyszczalni ścieków w Dębogórzu',
        position: ''
    },
    {
        imgUrl: oczyszczalnia2,
        name: 'Modernizacja i przebudowa komór napowietrzania w istniejącej oczyszczalni ścieków w Dębogórzu',
        position: ''
    },
    {
        imgUrl: oczyszczalnia4,
        name: 'Oczyszczalnia ścieków w Dębogórzu (dla gmin Gdynia i Rumia).Zdjęcie pokazuje konstrukcję bioreaktora oraz osadników wtórnych w trakcie realizacji. Obiekty zaprojektowano i wykonano w technologii monolitycznej -  nie stosując dylatacji trwałych.Podstawowe dane geometryczne: L=99.30m, B=54.20m, H=6.00m',
        position: ''
    },
]


const Projects = () => {
    return (
        <section className="our__projects">
            <div className="container">
                <div className="projects__content">
                </div>

                <div className="projects__wrapper">
                    {teamProjects.map((item, index) => (
                        <div className="projects__item" key={index}>
                            <div className="projects__img">
                                <img src={item.imgUrl} alt={item.name} />
                            </div>
                            <div className="projects__details">
                                <h4>{item.name}</h4>
                                <p className="description">{item.position}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Projects;