import React from 'react';
import '../../styles/hero.css';
import heroDarkImage from '../../imgaes/image006 Small.png';

const Hero = () => {
    return <section className="hero__section" id="strona_główna">
        <div className="container">
            <div className="hero__wrapper">
                <div className="hero__content">
                <div>
                    <h2>Witamy na naszej stronie, której wygląd zmieniać się może także pod wpływem
                        realizacji Państwa inwestycji.</h2>
                    <h2>Oferujemy projekty w branży konstrukcyjnej: terminowo, profesjonalnie, z pasją...</h2>
                    <h2 className="highlihgt">Dziękujemy za odwiedzenie naszej nowej strony. 
                    Jako nowczesna firma, chcemy dać Państwu możliwość pozostania w kontakcie z nami i z naszą ofertą.</h2>
                </div>
            
                </div>
                <div className="hero__img"></div>
                <img src={heroDarkImage} alt="oczyszczalnia"/>
            </div>
        </div>
    </section>     
}

export default Hero